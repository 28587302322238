import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isVODChannel } from '../utils/channel-helpers';
import { canShowChannelCategories, canShowSearch, canShowChannelTitle, getCanShowChannelShare, getCanShowChannelInfo, getCanShowSignIn, } from './app-settings';
import { isSubscriptionButtonVisible } from '../components/overlay-texts/channel/subscribe-button-text-utils';
var isWidgetChannelVODChannel = createSelector(getChannelForWidget, function (channel) { return isVODChannel(channel); });
export var isCategoriesDropdownVisible = createSelector(isWidgetChannelVODChannel, canShowChannelCategories, function (isVod, canShow) { return isVod && canShow; });
export var isSearchInputVisible = createSelector(isWidgetChannelVODChannel, canShowSearch, function (isVod, canShow) { return isVod && canShow; });
export var isClassicLayoutSearchBarVisible = createSelector(isCategoriesDropdownVisible, isSearchInputVisible, function (isCategories, isSearch) { return isCategories || isSearch; });
var getCanShowSubscriptionButton = createSelector(getChannelForWidget, function (channel) { return isSubscriptionButtonVisible({ channel: channel }); });
export var isClassicLayoutHeaderVisible = createSelector(canShowChannelTitle, getCanShowChannelShare, getCanShowChannelInfo, getCanShowSignIn, getCanShowSubscriptionButton, function (isTitle, isChannelShare, isChannelInfo, isSignIn, isSubscription) {
    return isTitle || isChannelShare || isChannelInfo || isSignIn || isSubscription;
});
